import { EditorBubble, useEditor } from 'novel';
import React, { Fragment, useEffect, type ReactNode } from 'react';
import { Button } from '../ui/button';
import { AISelector } from './ai-selector';
import Magic from '../ui/icons/magic';
import {} from 'novel/plugins';
import { removeAIHighlight } from 'novel/extensions';
import { AIContextData } from '@/types/types';
import { useKey } from '@/hooks/keyboard';

interface GenerativeMenuSwitchProps {
  children: ReactNode;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  context: AIContextData;
}
const GenerativeMenuSwitch = ({
  children,
  open,
  onOpenChange,
  context,
}: GenerativeMenuSwitchProps) => {
  const { editor } = useEditor();

  const handleHide = () => {
    onOpenChange(false);
    editor?.chain().unsetHighlight().run();
  };

  useKey('Enter', () => onOpenChange(true), { withMetaKey: true, withShift: true });

  useEffect(() => {
    if (!open && editor) removeAIHighlight(editor);
  }, [open]);

  return (
    <EditorBubble
      tippyOptions={{
        placement: open ? 'bottom-start' : 'bottom-end',
        onHidden: handleHide,
      }}
      className="flex w-fit max-w-[90vw] overflow-hidden rounded-md border border-muted bg-background shadow-xl"
    >
      {open ? (
        <AISelector open={open} onOpenChange={onOpenChange} context={context} />
      ) : (
        <Fragment>
          <Button
            className="gap-1 rounded-none"
            onClick={() => onOpenChange(true)}
            size="sm"
          >
            <Magic className="h-5 w-5" />
            Ask AI
          </Button>
          {children}
        </Fragment>
      )}
    </EditorBubble>
  );
};

export default GenerativeMenuSwitch;
