import { useCallback, useEffect, useState } from 'react';

const useLocalStorage = <T>(
  key: string,
  initialValue: T
  // eslint-disable-next-line no-unused-vars
): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState(initialValue);

  useEffect(() => {
    // Retrieve from localStorage
    const item = window.localStorage.getItem(key);
    if (item) {
      setStoredValue(JSON.parse(item));
    }
  }, [key]);

  const setValue = useCallback(
    (value: T) => {
      // Save state
      setStoredValue(value);
      // Save to localStorage
      window.localStorage.setItem(key, JSON.stringify(value));
    },
    [key]
  );
  return [storedValue, setValue];
};

export default useLocalStorage;
