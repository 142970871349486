'use client';

import { Command, CommandInput } from '@/components/tailwind/ui/command';

import { useCompletion } from 'ai/react';
import { toast } from 'sonner';
import { useEditor } from 'novel';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import AISelectorCommands from './ai-selector-commands';
import AICompletionCommands from './ai-completion-command';
import { ScrollArea } from '../ui/scroll-area';
import { Button } from '../ui/button';
import { ArrowUp } from 'lucide-react';
import Magic from '../ui/icons/magic';
import CrazySpinner from '../ui/icons/crazy-spinner';
import { addAIHighlight, getPrevText } from 'novel/extensions';
import { AIContextData } from '@/types/types';
import AIReplaceCommands from './ai-replace-commands';
import { MarkdownDiff } from '@/lib/diff';
import { useKey } from '@/hooks/keyboard';
//TODO: I think it makes more sense to create a custom Tiptap extension for this functionality https://tiptap.dev/docs/editor/ai/introduction

interface AISelectorProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  context: AIContextData;
}

export function AISelector({ open, onOpenChange, context }: AISelectorProps) {
  const { editor } = useEditor();
  const [inputValue, setInputValue] = useState('');
  const [selectedText, setSelectedText] = useState('');

  const { completion, complete, isLoading, data } = useCompletion({
    // id: "novel",
    api: '/api/generate',
    onResponse: (response) => {
      if (response.status === 429) {
        toast.error('You have reached your request limit for the day.');
        return;
      }
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const handleDiscard = () => {
    editor?.chain().unsetHighlight().focus().run();
    onOpenChange(false);
  };

  useKey('Escape', handleDiscard);

  const hasCompletion = completion.length > 0;

  // @ts-ignore
  const multipleChoices = data?.[0]?.data;

  return (
    <Command className="w-[350px]">
      {hasCompletion && (
        <div className="flex max-h-[400px]">
          <ScrollArea>
            <div className="p-2 px-4">
              <MarkdownDiff showDiffToggleButton oldValue={selectedText}>
                {completion}
              </MarkdownDiff>
            </div>
          </ScrollArea>
        </div>
      )}

      {isLoading && (
        <div className="flex h-12 w-full items-center px-4 text-sm font-medium text-muted-foreground text-purple-500">
          <Magic className="mr-2 h-4 w-4 shrink-0  " />
          AI is thinking
          <div className="ml-2 mt-1">
            <CrazySpinner />
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          <div className="relative">
            <CommandInput
              value={inputValue}
              onValueChange={setInputValue}
              autoFocus
              placeholder={
                multipleChoices
                  ? 'Filter'
                  : hasCompletion
                    ? 'Tell AI what to do next'
                    : 'Ask AI to edit or generate...'
              }
              onFocus={() => editor && addAIHighlight(editor)}
            />
            <Button
              disabled={!inputValue}
              size="icon"
              className="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 rounded-full bg-purple-500 hover:bg-purple-900"
              onClick={() => {
                if (!editor) return;
                const allEditorText = getPrevText(editor, { chars: 5000 });

                if (completion) {
                  setSelectedText(completion);

                  return complete(completion, {
                    body: { option: 'zap', command: inputValue, context, allEditorText },
                  }).then(() => setInputValue(''));
                }

                const slice = editor?.state.selection.content();
                const text = editor?.storage.markdown.serializer.serialize(slice?.content);

                setSelectedText(text);

                complete(text, {
                  body: { option: 'zap', command: inputValue, context, allEditorText },
                }).then(() => setInputValue(''));
              }}
            >
              <ArrowUp className="h-4 w-4" />
            </Button>
          </div>

          {multipleChoices ? (
            <>
              <AIReplaceCommands
                completions={multipleChoices}
                selectedText={selectedText}
                onDiscard={handleDiscard}
              />
            </>
          ) : (
            <>
              {hasCompletion ? (
                <AICompletionCommands onDiscard={handleDiscard} completion={completion} />
              ) : (
                <AISelectorCommands
                  onSelect={(value, option) => {
                    if (!editor) return;
                    const allEditorText = getPrevText(editor, { chars: 5000 });

                    const slice = editor?.state.selection.content();
                    const text = editor?.storage.markdown.serializer.serialize(slice?.content);

                    setSelectedText(text);

                    return complete(value, { body: { option, context, allEditorText } });
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </Command>
  );
}
