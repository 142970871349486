'use client';
import React, { useState, useEffect } from 'react';
import { Checkbox } from './checkbox';
import { Textarea } from './textarea';
import { ContextItem } from '@/types/types';

export const ContextList = ({
  context,
  onChange,
}: {
  context: ContextItem[];
  onChange: (context: ContextItem[]) => void;
}) => {
  const handleCheckboxChange = (index: number) => {
    const newContexts = context.map((context, idx) => {
      if (idx === index) {
        return { ...context, checked: !context.checked };
      }
      return context;
    });

    onChange(newContexts);
  };

  const handleTextChange = (text: string, index: number) => {
    const newContexts = context.map((context, idx) => {
      if (idx === index) {
        return { ...context, text: text };
      }
      return context;
    });

    onChange(newContexts);
  };

  return (
    <div className="flex-1 space-y-4 overflow-auto px-1 py-4">
      {context.map((context, index) => (
        <div
          key={context.id}
          className="flex items-start"
          style={{ opacity: context.checked ? 1 : 0.4 }}
        >
          <Checkbox
            id={context.id}
            checked={context.checked}
            onCheckedChange={() => handleCheckboxChange(index)}
          />
          <Textarea
            className="ml-2 flex-1"
            placeholder="Context"
            value={context.text}
            onChange={(e) => handleTextChange(e.target.value, index)}
          />
        </div>
      ))}
    </div>
  );
};
// {context.map((context, index) => (
//   <div key={context.id} className="flex items-start">
//     <Checkbox
//       // id={context.id}
//       checked={context.checked}
//       onChange={() => handleCheckboxChange(index)}
//     />
//     <Textarea
//       className="ml-2 flex-1"
//       placeholder="Context information"
//       value={context.text}
//       onChange={(e) => handleTextChange(e.target.value, index)}
//     />
//   </div>
// ))}
