import { useEffect } from 'react';

export const useKey = (
  key: string,
  callback: () => void,
  { withMetaKey, withShift } = { withMetaKey: false, withShift: false }
) => {
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === key) {
        if (withMetaKey && !e.metaKey) return;
        if (withShift && !e.shiftKey) return;

        console.log('e.key: ', e.key);
        e.preventDefault();
        callback();
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);
};
