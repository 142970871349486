'use client';
import { Github } from '@/components/tailwind/ui/icons';
import { Button } from '@/components/tailwind/ui/button';
import Menu from '@/components/tailwind/ui/menu';
import Link from 'next/link';
import TailwindAdvancedEditor from '@/components/tailwind/advanced-editor';
import { Dialog, DialogContent, DialogTrigger } from '@/components/tailwind/ui/dialog';
import { ScrollArea } from '@/components/tailwind/ui/scroll-area';
import { BookOpen, ChevronRight } from 'lucide-react';
import { SidePanel } from '@/components/tailwind/ui/side-panel';
import { useState } from 'react';
import { ContextList } from '@/components/tailwind/ui/context-list';
import {
  InstructionsForm,
  InstructionsFormSchemaType,
} from '@/components/tailwind/ui/instructions-form';
import useLocalStorage from '@/hooks/use-local-storage';

export default function Page() {
  const [context, setContext] = useLocalStorage('context', [
    { id: 'context-1', checked: true, text: '' },
    { id: 'context-2', checked: false, text: '' },
    { id: 'context-3', checked: false, text: '' },
  ]);
  const [instructions, setInstructions] = useLocalStorage<InstructionsFormSchemaType>(
    'instructions',
    {
      type: 'Linkedin-post',
      targetAudience: 'Digital leaders',
      goal: 'Generate sales leads for AI POCs',
      framework: 'pas-Problem-Agitate-Solution',
      // voiceAndTone: '',
      // voiceAndToneDetails: '',
      customInstructions: '',
    }
  );

  return (
    <div className="flex min-h-screen flex-col items-center gap-4 py-4 sm:px-5">
      <div className="fixed left-0 top-1/2 z-10 flex">
        <SidePanel title="Instructions">
          <InstructionsForm instructions={instructions} onChange={setInstructions} />
        </SidePanel>
      </div>

      <div className="fixed right-0 top-1/2 z-10 flex">
        <SidePanel title="Context" isRight>
          <ContextList context={context} onChange={setContext} />
        </SidePanel>
      </div>

      <div className="mb-full mb-10 flex w-full max-w-screen-lg items-center">
        <div className="ml-auto">
          <Menu />
        </div>
      </div>

      <TailwindAdvancedEditor context={{ context, instructions }} />
    </div>
  );
}
