import React from 'react';
import { CommandGroup, CommandItem, CommandSeparator } from '../ui/command';
import { useEditor } from 'novel';
import { TrashIcon } from 'lucide-react';
import { MarkdownDiff } from '@/lib/diff';

const AIReplaceCommands = ({
  selectedText,
  completions,
  onDiscard,
}: {
  selectedText: string;
  completions: string[];
  onDiscard: () => void;
}) => {
  const { editor } = useEditor();
  return (
    <>
      <CommandGroup>
        {completions.map((completion) => (
          <CommandItem
            className="gap-2 px-4"
            value={completion}
            onSelect={() => {
              const selection = editor?.view.state.selection;

              if (selection)
                editor
                  ?.chain()
                  .focus()
                  .insertContentAt({ from: selection.from, to: selection.to }, completion)
                  .run();
            }}
          >
            <MarkdownDiff hideDeleted oldValue={selectedText}>
              {completion}
            </MarkdownDiff>
          </CommandItem>
        ))}
      </CommandGroup>
      <CommandSeparator />

      <CommandGroup>
        <CommandItem onSelect={onDiscard} value="thrash" className="gap-2 px-4">
          <TrashIcon className="h-4 w-4 text-muted-foreground" />
          Discard
        </CommandItem>
      </CommandGroup>
    </>
  );
};

export default AIReplaceCommands;
