import { Button } from '@/components/tailwind/ui/button';
import { diffWords } from 'diff';
import { View } from 'lucide-react';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

type DiffWordProps = {
  oldValue: string;
  newValue: string;
  hideDeleted?: boolean;
  children?: string;
};

export function diffWordMarkdown({ oldValue, newValue, hideDeleted }: DiffWordProps) {
  const diff = diffWords(oldValue, newValue);

  const html =
    diff.length > 2 //&& diff.length < 20
      ? diff
          .map((part, index) => {
            // if (/[*_~#]+/.test(part.value)) return part.value;

            const className = part.added
              ? 'text-purple-500'
              : part.removed
                ? 'line-through opacity-50 ' + (hideDeleted ? 'hidden' : '')
                : '';
            return `<span class="inline ${className}">${/^[#-]/.test(part.value) ? '\n' + part.value : part.value}</span>`;
          })
          .join('')
      : newValue;

  return html;
}

export function DiffWordTSX({ oldValue, newValue }: DiffWordProps) {
  const diff = diffWords(oldValue, newValue);

  const [showDiff, setShowDiff] = useState(false);

  return (
    <span onClick={() => setShowDiff((diff) => !diff)}>
      {showDiff && diff.length > 2
        ? diff.map((part, index) => {
            const className = part.added
              ? // add green underline
                'text-gray-900 font-bold'
              : part.removed
                ? 'text-red-500 hidden'
                : '';
            return (
              <span key={index} className={className}>
                {part.value}
              </span>
            );
          })
        : newValue}
    </span>
  );
}

export const MarkdownDiff = ({
  oldValue,
  hideDeleted,
  children,
  showDiffToggleButton = false,
}: {
  oldValue: string;
  hideDeleted?: boolean;
  children?: string;
  showDiffToggleButton?: boolean;
}) => {
  const [showDiff, setShowDiff] = useState(true);

  return (
    children && (
      <div className="prose prose-sm relative">
        {showDiffToggleButton && (
          <button
            className="absolute -right-4 -top-6 flex h-8 w-8 items-center justify-center"
            onClick={() => setShowDiff((diff) => !diff)}
          >
            <View className="h-3 w-3" />
          </button>
        )}
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw] as any}
          components={{
            del: ({ children }) => <span className="line-through opacity-50">{children}</span>,
            em: ({ children }) => <strong className="text-purple-500">{children}</strong>,
          }}
        >
          {showDiff ? diffWordMarkdown({ oldValue, newValue: children, hideDeleted }) : children}
        </ReactMarkdown>
      </div>
    )
  );
};
