import React from 'react';
import { CommandGroup, CommandItem, CommandSeparator } from '../ui/command';
import {
  ArrowDownWideNarrow,
  ArrowLeftRight,
  ArrowRight,
  SpellCheck,
  Menu,
  RefreshCcwDot,
  SmilePlus,
  StepForward,
  Baby,
  Type,
} from 'lucide-react';
import { useEditor } from 'novel';
import { Command, CommandList } from 'cmdk';
import { ArrowUpNarrowWide } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';
// import { getPrevText } from 'novel/extensions';

type Item = {
  value: string;
  label: string;
  icon: LucideIcon;
  isSubmenu?: boolean;
};

const optionsGroups: Array<[string, Item[]]> = [
  [
    'Edit or review selection',
    [
      {
        value: 'rewrite',
        label: 'Rewrite',
        icon: RefreshCcwDot,
      },
      {
        value: 'fix',
        label: 'Fix grammar',
        icon: SpellCheck,
      },
      {
        value: 'simplify',
        label: 'Simplify',
        icon: Baby,
      },
    ],
  ],
  [
    'Expand or shorten text',
    [
      {
        value: 'shorter',
        label: 'Make shorter',
        icon: ArrowUpNarrowWide,
      },
      {
        value: 'longer',
        label: 'Make longer',
        icon: ArrowDownWideNarrow,
      },
      {
        value: 'continue',
        label: 'Continue writing',
        icon: StepForward,
      },
    ],
  ],
  [
    'Keep original unchanged',
    [
      {
        value: 'emoji',
        label: 'Add emojis',
        icon: SmilePlus,
      },
      {
        value: 'format',
        label: 'Format text',
        icon: Type,
      },
      {
        value: 'synonyms',
        label: 'Get synonyms',
        icon: ArrowLeftRight,
        isSubmenu: true,
      },
      {
        value: 'alternatives',
        label: 'Get alternatives',
        icon: Menu,
        isSubmenu: true,
      },
    ],
  ],
];

interface AISelectorCommandsProps {
  onSelect: (value: string, option: string) => void;
}

const AISelectorCommands = ({ onSelect }: AISelectorCommandsProps) => {
  const { editor } = useEditor();

  return (
    <>
      {optionsGroups.map(([groupTitle, options]) => (
        <CommandGroup heading={groupTitle}>
          {options.map((option) => (
            <CommandItem
              onSelect={(value) => {
                const slice = editor?.state.selection.content();
                const text = editor?.storage.markdown.serializer.serialize(slice?.content);

                onSelect(text, value);
              }}
              className="flex gap-2 px-4"
              key={option.value}
              value={option.value}
            >
              <option.icon className="h-4 w-4 text-purple-500" />
              {option.label}
              {option.isSubmenu && <ArrowRight className="ml-auto h-4 w-4 " />}
            </CommandItem>
          ))}
        </CommandGroup>
      ))}

      <CommandSeparator />

      {/* <CommandGroup heading="Use AI to do more">
        <CommandItem
          onSelect={() => {
            if (!editor) return;
            const text = getPrevText(editor, { chars: 5000 });
            console.log('text: ', text);
            onSelect(text, 'continue');
          }}
          value="continue"
          className="gap-2 px-4"
        >
          <StepForward className="h-4 w-4 text-purple-500" />
          Continue writing
        </CommandItem>
      </CommandGroup> */}
    </>
  );
};

export default AISelectorCommands;
