'use client';

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z, ZodSchema } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { SelectValue, SelectTrigger, SelectItem, SelectContent, Select } from './select';
import { Input } from './input';
import { Label } from './label';
import { ToggleGroup, ToggleGroupItem } from './toggle-group';
import { Form, FormField } from './form';
import { toast } from './use-toast';
import { Button } from './button';
import { Textarea } from './textarea';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './accordion';

const voiceAndToneKeys = ['case-study', 'ginetta-social', 'ginetta-website'] as const;
const voiceAndTone: Record<(typeof voiceAndToneKeys)[number], string> = {
  'ginetta-website': `Ginetta’s voice and tone are professional yet approachable, emphasizing user-centricity,
continuous improvement, and collaboration. The tone is confident and assertive, often using strong, declarative sentences.
Vocabulary is clear, concise, and focused on key concepts like "human centricity," "continuous improvement," and "seamless collaboration."
The language is motivational and inclusive, aiming to inspire trust and engagement.
Sentence structure varies from short, impactful statements to more detailed explanations, reflecting a balance between simplicity and thoroughness.
Overall, Ginetta communicates a commitment to creating valuable, user-focused digital experiences through teamwork and constant refinement.`,
  'case-study': `Write with the following style, voice, and tone:
Emphasize clarity and modernity with a forward-thinking and innovative approach, focusing on user-centered outcomes.
Adopt a formal yet engaging tone that communicates confidence and expertise in digital transformation,
particularly in adapting traditional platforms to current user needs and technologies.
Use a structured and analytical style, highlighting challenges, solutions, and outcomes in a clear sequence.
Leverage professional jargon appropriately, but balance it with accessible language to ensure inclusivity.
Include motivational and positive affirmations reflecting team spirit and a commitment to excellence,
encouraging a sense of pride and achievement within a collaborative framework.
Conclude with a reflective and forward-looking sentiment that reinforces the project’s impact and future potential.`,
  'ginetta-social': `Write with the following style, voice, and tone: Employ a vibrant and enthusiastic voice that communicates genuine excitement and gratitude.
The tone should be appreciative and inclusive, frequently acknowledging and thanking the audience or participants.
The style should be informal, using emojis to add a friendly touch and make the message more engaging.
Use direct address ("you") to create a sense of conversation and connection with the reader.
The language should be clear and motivational, encouraging involvement and sharing of ideas
Utilize hashtags to categorize the content and emphasize key themes like innovation and professionalism.
Keep sentences relatively short and energetic, and use rhetorical questions to engage the reader's thoughts and feelings.`,
};

// Define the form schema
const formSchema = z.object({
  type: z.string(),
  targetAudience: z.string(),
  goal: z.string(),
  framework: z.string(),
  voiceAndTone: z.enum(voiceAndToneKeys).optional(),
  voiceAndToneDetails: z.string().optional(),
  customInstructions: z.string(),
});

export type InstructionsFormSchemaType = z.infer<typeof formSchema>;

export function InstructionsForm({
  instructions,
  onChange,
}: {
  instructions: InstructionsFormSchemaType;
  onChange?: (value: InstructionsFormSchemaType) => void;
}) {
  const form = useForm<InstructionsFormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: instructions,
  });

  const { watch } = form;

  const formData = watch(); // This will subscribe to all field changes

  useEffect(() => {
    onChange?.(formData); // Emit the changes to the parent component
  }, [...Object.values(formData), onChange]);

  return (
    <Form {...form}>
      <ul className="flex flex-1 flex-col gap-5 overflow-auto px-1 py-4 text-left">
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <li className="flex flex-col gap-2">
              <Label htmlFor="type">Type</Label>
              <Select {...field} onValueChange={field.onChange} defaultValue={field.value}>
                <SelectTrigger id="type">
                  <SelectValue placeholder="What's are you writing?" />
                </SelectTrigger>
                <SelectContent position="popper">
                  <SelectItem value="Blog-Article">Blog Article</SelectItem>
                  <SelectItem value="Linkedin-post">Linkedin Post</SelectItem>
                  <SelectItem value="Tweet">Tweet</SelectItem>
                  <SelectItem value="Other">Other</SelectItem>
                </SelectContent>
              </Select>
            </li>
          )}
        />

        <FormField
          control={form.control}
          name="targetAudience"
          render={({ field }) => (
            <li className="flex flex-col gap-2">
              <Label htmlFor="targetAudience">Target Audience</Label>
              <Input
                id="targetAudience"
                placeholder="Enter your target audience"
                {...field}
                onChange={field.onChange}
                defaultValue={field.value}
              />
            </li>
          )}
        />

        <FormField
          control={form.control}
          name="goal"
          render={({ field }) => (
            <li className="flex flex-col gap-2">
              <Label htmlFor="goal">Goal</Label>
              <Input
                id="goal"
                placeholder="Enter your goal"
                {...field}
                onChange={field.onChange}
                defaultValue={field.value}
              />
            </li>
          )}
        />

        <FormField
          control={form.control}
          name="framework"
          render={({ field }) => (
            <li className="flex flex-col gap-2">
              <Label htmlFor="framework">Framework</Label>
              <Select {...field} onValueChange={field.onChange} defaultValue={field.value}>
                <SelectTrigger id="framework">
                  <SelectValue placeholder="Select an option" />
                </SelectTrigger>
                <SelectContent position="popper">
                  <SelectItem value="none">None</SelectItem>
                  <SelectItem value="aida-Attention-Interest-Desire-Action">
                    AIDA{' '}
                    <span className="m-4 self-end text-gray-500">
                      Attention-Interest-Desire-Action
                    </span>
                  </SelectItem>
                  <SelectItem value="pas-Problem-Agitate-Solution">
                    PAS <span className="m-4 self-end text-gray-500">Problem-Agitate-Solution</span>
                  </SelectItem>
                  <SelectItem value="star-Situation-Task-Action-Result">
                    STAR{' '}
                    <span className="m-4 self-end text-gray-500">Situation-Task-Action-Result</span>
                  </SelectItem>
                  <SelectItem value="bab-Before-After-Bridge">
                    BAB <span className="m-4 self-end text-gray-500">Before-After-Bridge</span>
                  </SelectItem>
                </SelectContent>
              </Select>
            </li>
          )}
        />

        <FormField
          control={form.control}
          name="voiceAndTone"
          render={({ field }) => (
            <li className="-mt-2 flex flex-col gap-2">
              <Accordion type="single" collapsible>
                <AccordionItem value="item-1" className="border-none">
                  <AccordionTrigger className="-mb-2">
                    <Label htmlFor="voiceAndTone">Voice and Tone</Label>
                  </AccordionTrigger>

                  <Select
                    {...field}
                    onValueChange={(v) => {
                      form.setValue(
                        'voiceAndToneDetails',
                        voiceAndTone[v as (typeof voiceAndToneKeys)[number]]
                      );
                      return field.onChange(v);
                    }}
                    defaultValue={field.value}
                  >
                    <SelectTrigger id="voiceAndTone">
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>

                    <SelectContent position="popper">
                      <SelectItem value="ginetta-website">Ginetta Website</SelectItem>
                      <SelectItem value="ginetta-social">Ginetta Social</SelectItem>
                      <SelectItem value="case-study">Case Study</SelectItem>
                    </SelectContent>
                  </Select>

                  <AccordionContent className="-m-1 p-1">
                    <FormField
                      control={form.control}
                      name="voiceAndToneDetails"
                      render={({ field }) => (
                        <Textarea
                          id="voiceAndToneDetails"
                          placeholder="Enter your target audience"
                          {...field}
                          onChange={field.onChange}
                          value={field.value}
                          className="mt-1 min-h-64"
                        />
                      )}
                    />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </li>
          )}
        />

        <FormField
          control={form.control}
          name="customInstructions"
          render={({ field }) => (
            <li className="flex flex-col gap-2">
              <Label htmlFor="customInstructions">Custom Instructions</Label>
              <Textarea
                id="customInstructions"
                placeholder="Enter your custom instructions"
                {...field}
                onChange={field.onChange}
                value={field.value}
                className="mt-1 min-h-64"
              />
            </li>
          )}
        />
      </ul>
    </Form>
  );
}
