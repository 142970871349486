'use client';

import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from './button';
import {
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
  SheetFooter,
  SheetClose,
  Sheet,
} from './sheet';
import { Label } from './label';
import { Input } from './input';
import { cn } from '@/lib/utils';

type SidePanelProps = {
  title: string;
  isRight?: boolean;
  children: React.ReactNode;
};

export function SidePanel({ isRight, title, children }: SidePanelProps) {
  return (
    <Sheet>
      <SheetTrigger className={cn('bg-background shadow-sm py-4 px-1', isRight ? 'rounded-l-sm' : 'rounded-r-sm')}>
        {isRight ? <ChevronLeft /> : <ChevronRight />}
      </SheetTrigger>
      <SheetContent side={isRight ? 'right' : 'left'}>
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
          <SheetDescription>{children}</SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
}
